import React, { Component } from 'react';
import './App.css';
import BookJob from './BookJob';
import btn_icon_back_jobdetails from './images/btn_icon_back_jobdetails.png';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class JobDetailsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, date, time, service, city, postCode, payment, specificInstructions, customerEmail

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['book'];
      let serviceOptions = this.props.appActions.serviceOptions_book;
      if ( !this.props.appActions.dataSheetLoaded['book']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("book", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['book'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elTextBlock = (ev) => {
    window.open('https://pixelinventory.co.uk/london/clerks/terms-conditions', '_blank');
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elTextBlock = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    return (
      <div className="AppScreen JobDetailsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="baseFont elTextBlock" style={style_elTextBlock} onClick={this.onClick_elTextBlock} >
            <div>{this.props.locStrings.jobdetails_textblock_817676}</div>
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elBookJob">
            <div>
              <BookJob date={"Wednesday, October 23, 2019"} time={"10 AM"} service={"Inventory & Check In"} city={"London"} postCode={"N12 9TB"} payment={"£ 100"} specificInstructions={"Please follow up with Doug from Foxtons on 07875801485"} streetAddress={"17-19 Torrington Park"} {...this.props} ref={(el)=> this._elBookJob = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Job Details</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_jobdetails} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elProgress">
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#0093d5'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
