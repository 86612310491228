import React, { Component } from 'react';
import './App.css';
import img_elTylerfranta603090unsplash from './images/DashboardScreen_elTylerfranta603090unsplash_201633.jpg';
import ScheduledItem from './ScheduledItem';
import AccountButton from './AccountButton';
import MenuButton from './MenuButton';
import btn_icon_back_dashboard from './images/btn_icon_back_dashboard.png';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class DashboardScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>Your Next Appointment</div>),
      text_plainText: "Your Next Appointment",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elMenuButtonCopy2 = (ev) => {
    // Go to screen 'Menu'
    this.props.appActions.goToScreen('menu', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const dataSheet_book = this.props.dataSheets['book'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elTylerfranta603090unsplash = {
      height: 'auto',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elMenuButtonCopy2 = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen DashboardScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elTylerfranta603090unsplash">
            <img style={style_elTylerfranta603090unsplash} src={img_elTylerfranta603090unsplash} alt=""  />
          </div>
          
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{this.props.locStrings.dashboard_text2_589450}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elScheduledItem">
            <div>
              <ScheduledItem {...dataSheet_book.items[0]} ref={(el)=> this._elScheduledItem = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Dashboard</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_dashboard} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elAccountButton">
              <AccountButton ref={(el)=> this._elAccountButton = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps elMenuButtonCopy2" style={style_elMenuButtonCopy2} onClick={this.onClick_elMenuButtonCopy2} >
              <MenuButton ref={(el)=> this._elMenuButtonCopy2 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
