import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_firebaseJobs extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['streetAddress'] = "17-19 Torrington Park";
    item['document_key'] = "2YryvnWSotY2ro5l6HRu";
    item['time'] = "10 AM";
    item['city'] = "London";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['typeFormId'] = "p2vh531ofjxyqt09p2vhc6l65694h3wo";
    item['document_ref'] = "job/2YryvnWSotY2ro5l6HRu";
    item['size'] = "Medium 2 & 3 Bed (£100)";
    item['customerLastName'] = "Dehnad";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['date'] = "Wednesday, October 23, 2019";
    item['customerFirstName'] = "Amir";
    item['houseNumber'] = "Flat 6, Gable Lodge";
    item['customerPhone'] = "07780808609";
    item['formId'] = "IIuX90";
    item['extras'] = "None";
    item['service'] = "Inventory & Check In";
    item['payment'] = "£ 100";
    item['postCode'] = "N12 9TB";
    item['additionalRooms'] = "None";
    item['price'] = "100";
    item['JobId'] = "2YryvnWSotY2ro5l6HRu";
    item['key'] = "0";
    
    item = {};
    this.items.push(item);
    item['streetAddress'] = "17";
    item['document_key'] = "Sa7EGnGldEB1TdtAa56f";
    item['time'] = "10";
    item['city'] = "London";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['typeFormId'] = "p2vh531ofjxyqt09p2vhc6l65694h3wo";
    item['document_ref'] = "job/Sa7EGnGldEB1TdtAa56f";
    item['size'] = "Medium 2 & 3 Bed (£100)";
    item['customerLastName'] = "Dehnad";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['date'] = "Wednesday, October 23, 2019";
    item['customerFirstName'] = "Amir";
    item['houseNumber'] = "Flat 6, Gable Lodge";
    item['customerPhone'] = "7780808609";
    item['formId'] = "IIuX90";
    item['extras'] = "None";
    item['service'] = "Inventory & Check In";
    item['payment'] = "100";
    item['postCode'] = "N12 9TB";
    item['additionalRooms'] = "None";
    item['price'] = "100";
    item['JobId'] = "Sa7EGnGldEB1TdtAa56f";
    item['key'] = "2";
    
    item = {};
    this.items.push(item);
    item['document_key'] = "ZXTDLKkcBrK8hgMbL4Y0";
    item['time'] = "05 PM";
    item['city'] = "Feltham";
    item['customerEmail'] = "Mr.Rajeshgoud@googlemail.com";
    item['typeFormId'] = "gdxx6fl0jjn3yi6gdxxegah6upp0q6t9";
    item['size'] = "Medium 2 & 3 Bed (£100)";
    item['customerLastName'] = "Dasari";
    item['specificInstructions'] = "Aleksandra - +447402620020\nIncase you couldn't reach, Please call me directly.";
    item['date'] = "Sunday, December 1, 2019";
    item['customerFirstName'] = "Rajesh";
    item['houseNumber'] = "Flat 93";
    item['customerPhone'] = "07545501488";
    item['formId'] = "IIuX90";
    item['extras'] = "None";
    item['service'] = "Inventory Report";
    item['payment'] = "£ 100";
    item['postCode'] = "TW134GQ";
    item['additionalRooms'] = "None";
    item['price'] = "100";
    item['JobId'] = "3gdxx6fl0jjn3yi6gdxxegah6upp0q6t9";
    item['streetAddress'] = "Highfield Road";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "ZZFa1I7BEPw4WhfMxj8P";
    item['time'] = "10 AM";
    item['city'] = "croydon";
    item['customerEmail'] = "van24wallace@yahoo.co.uk";
    item['typeFormId'] = "0avry7oywh6pg144bsn0avrync17mz0j";
    item['size'] = "Small Studio & 1 Bed (£80)";
    item['customerLastName'] = "wallace";
    item['specificInstructions'] = "1412 is the code for the key safe";
    item['date'] = "Thursday, November 7, 2019";
    item['customerFirstName'] = "vanessa";
    item['houseNumber'] = "146";
    item['customerPhone'] = "07913926561";
    item['formId'] = "IIuX90";
    item['extras'] = "None";
    item['service'] = "Inventory & Check In";
    item['payment'] = "£ 80";
    item['postCode'] = "se25 6xb";
    item['additionalRooms'] = "None";
    item['price'] = "80";
    item['JobId'] = "30avry7oywh6pg144bsn0avrync17mz0j";
    item['streetAddress'] = "whitehorse lane";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "SE3 9HS";
    item['specificInstructions'] = "I will email you details of this one and inventory reports as it's the 4 bed house share we have, however it's a check-out for the whole house as landlord is giving up property.";
    item['formId'] = "IIuX90";
    item['JobId'] = "3i4wxfjnc1mbwmi4wxfse696xiq0ve3em";
    item['customerPhone'] = "07545069699";
    item['customerEmail'] = "amber@thehomecloud.co.uk";
    item['streetAddress'] = "89 Heathlee Road";
    item['time'] = "12 PM";
    item['houseNumber'] = "89";
    item['customerLastName'] = "Choinski";
    item['size'] = "Large 4+ Bed (£150)";
    item['price'] = "150";
    item['date'] = "Saturday, November 30, 2019";
    item['typeFormId'] = "i4wxfjnc1mbwmi4wxfse696xiq0ve3em";
    item['city'] = "Blackheath";
    item['document_key'] = "avBtee7oxEq1jIoRdgYs";
    item['service'] = "Check Out Report";
    item['customerFirstName'] = "Amber";
    item['payment'] = "£ 150";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "igHcBIjeNiyqhRJgiIOd";
    item['time'] = "10";
    item['city'] = "London";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['typeFormId'] = "p2vh531ofjxyqt09p2vhc6l65694h3wo";
    item['size'] = "Medium 2 & 3 Bed (£100)";
    item['customerLastName'] = "Dehnad";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['date'] = "Wednesday, October 23, 2019";
    item['customerFirstName'] = "Amir";
    item['houseNumber'] = "Flat 6, Gable Lodge";
    item['customerPhone'] = "7780808609";
    item['formId'] = "IIuX90";
    item['extras'] = "None";
    item['service'] = "Inventory & Check In";
    item['payment'] = "£ 100";
    item['postCode'] = "N12 9TB";
    item['additionalRooms'] = "None";
    item['price'] = "100";
    item['jobId'] = "2";
    item['streetAddress'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "nrxHytj5bGeInVDxghky";
    item['time'] = "10 AM";
    item['city'] = "London";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['typeFormId'] = "p2vh531ofjxyqt09p2vhc6l65694h3wo";
    item['size'] = "Medium 2 & 3 Bed (£100)";
    item['customerLastName'] = "Dehnad";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['date'] = "Wednesday, October 23, 2019";
    item['customerFirstName'] = "Amir";
    item['houseNumber'] = "Flat 6, Gable Lodge";
    item['customerPhone'] = "07780808609";
    item['formId'] = "IIuX90";
    item['extras'] = "None";
    item['service'] = "Inventory & Check In";
    item['payment'] = "£ 100";
    item['postCode'] = "N12 9TB";
    item['additionalRooms'] = "None";
    item['price'] = "100";
    item['JobId'] = "2";
    item['streetAddress'] = "17-19 Torrington Park";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "qUlIqilL51XnHRJmWQCQ";
    item['time'] = "10 AM";
    item['city'] = "Putney";
    item['customerEmail'] = "amber@thehomecloud.co.uk";
    item['typeFormId'] = "jrh5n8hkir3dbbijrhsbuk3l6lbpvdje";
    item['size'] = "Medium 2 & 3 Bed (£100)";
    item['customerLastName'] = "Choinski";
    item['specificInstructions'] = "Keys are with the selling agents ready for collection. Please collect from James Pendleton agents, address is Unit 5, Three Eastfields Avenue, Wandsworth, SW18 1GN, I will inform them you will be collecting. They have keys for both flat 3 and for flat 9, please collect both and pass over keys for flat 3 to new tenants. If you wouldn't mind to keep keys for flat 9 with you, as we will hopefully have a tenant for this flat soon also and need another check-in so might be easiest for you to keep them rather than having to collect separately for other flat later on in time.\n\nTenant would like to meet for check-in at 10am if possible, his name is George and his mobile number is 07778675417.";
    item['date'] = "Wednesday, November 27, 2019";
    item['customerFirstName'] = "Amber";
    item['houseNumber'] = "2 Monserrat Road";
    item['customerPhone'] = "07545069699";
    item['formId'] = "IIuX90";
    item['extras'] = "None";
    item['service'] = "Inventory & Check In";
    item['payment'] = "£ 100";
    item['postCode'] = "SW15 2LA";
    item['additionalRooms'] = "None";
    item['price'] = "100";
    item['JobId'] = "3jrh5n8hkir3dbbijrhsbuk3l6lbpvdje";
    item['streetAddress'] = "Flat 3, One Putney";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "t2x13Nb3iFNuTruED8Zd";
    item['time'] = "10 AM";
    item['city'] = "London";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['typeFormId'] = "p2vh531ofjxyqt09p2vhc6l65694h3wo";
    item['size'] = "Medium 2 & 3 Bed (£100)";
    item['customerLastName'] = "Dehnad";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['date'] = "Wednesday, October 23, 2019";
    item['customerFirstName'] = "Amir";
    item['houseNumber'] = "Flat 6, Gable Lodge";
    item['customerPhone'] = "07780808609";
    item['formId'] = "IIuX90";
    item['extras'] = "None";
    item['service'] = "Inventory & Check In";
    item['payment'] = "£ 100";
    item['postCode'] = "N12 9TB";
    item['additionalRooms'] = "None";
    item['price'] = "100";
    item['JobId'] = "3p2vh531ofjxyqt09p2vhc6l65694h3wo";
    item['streetAddress'] = "17-19 Torrington Park";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "xnFLYr4xkYOwvFUDBFa9";
    item['time'] = "02 PM";
    item['city'] = "London";
    item['customerEmail'] = "leedein@aol.com";
    item['typeFormId'] = "qah63q7w80k7aw3s8gvrpveqah6ivu95";
    item['size'] = "Medium 2 & 3 Bed (£100)";
    item['customerLastName'] = "Dein";
    item['date'] = "Thursday, December 5, 2019";
    item['payment'] = "£ 1";
    item['customerFirstName'] = "Lee";
    item['houseNumber'] = "45 Beaufort Park";
    item['customerPhone'] = "07772007086";
    item['formId'] = "IIuX90";
    item['extras'] = "None";
    item['service'] = "Inventory Report";
    item['postCode'] = "NW11 6BS";
    item['additionalRooms'] = "None";
    item['price'] = "1";
    item['JobId'] = "3qah63q7w80k7aw3s8gvrpveqah6ivu95";
    item['streetAddress'] = "Beaufort Drive";
    item.key = key++;
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      addItemPromise = collection.doc(item.document_key).set(item);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          item.document_key = docRef.id;
          item.document_ref = docRef;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    //const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = item.document_ref;
    
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    //const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = item.document_ref;
    
    docRef.update((({ key, document_ref, document_key, ...item }) => (item))( item ))
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
