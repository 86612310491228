import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text_447763";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text_458265";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text_535333";
    item['en'] = "Your Next Appointment";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_999912";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_button_695348";
    item['en'] = "Call Support";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text2_273670";
    item['en'] = "Your Next Appointment";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text3_359508";
    item['en'] = "Hello :)";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_text_203119";
    item['en'] = "Invitations";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_text_698682";
    item['en'] = "Appointments";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_textcopy_316632";
    item['en'] = "Invoices";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_textcopy2_912579";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_textcopy2_863852";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu2_textcopy2_374590";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu4_text_899165";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_text_297687";
    item['en'] = "Mon 24 Oct 2019 10AM SW15 Putney\nCheck In 2/3 Bed Furnished £100";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_text_249767";
    item['en'] = "Mon 24 Oct 2019 10AM SW15 Putney\nCheck In 2/3 Bed Furnished ￡100";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_button_977925";
    item['en'] = "Details";
    
    item = {};
    this.items.push(item);
    item['key'] = "profiledetails_text_61831";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "profilepic_text_360850";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "profilepic_text_745254";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "accountdetails_text_874480";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "accountdetails_text_240451";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "accountdetails_textcopy_343608";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "accountdetails_textcopy2_732624";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text2_589450";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "menubutton2_iconbutton_507638";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_541337";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_505740";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textcopy_167085";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textcopy2_339724";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textcopy3_29863";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_button_743412";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_button_872430";
    item['en'] = "Details";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_textblock_655127";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitems_textcopy_400200";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitems_text2_731458";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitems_text2_667358";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_403854";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_text2_750668";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy_688762";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy2_789885";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy3_369397";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy4_212587";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy4_928574";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_button2_827920";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_button_384400";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy6_199691";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_button_680217";
    item['en'] = "View Details";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy7_969194";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy8_120005";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_textblock_172533";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_textblock_701496";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_button_717367";
    item['en'] = "Accept Job";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem_text_694238";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "editaccount_button_357454";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "accountdetails_button_1036199";
    item['en'] = "Edit Account";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_text_754373";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_text_587116";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_text_317930";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_text_915320";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_text_789122";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_text_398220";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_text_274010";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_text_524036";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_text_112139";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy9_784599";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem_text_149593";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem_textcopy_912962";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem_text_167423";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem_textcopy_984157";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_button_217918";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_button_673382";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_textblockcopy_28259";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_text_286048";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_text_256875";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_button_669379";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "bookjob_textcopy3_163090";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem_textcopy2_124774";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem_textcopy3_449331";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem_textcopy4_253040";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy10_856837";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobitem_textcopy2_52908";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem_button_110252";
    item['en'] = "View Job";
    
    item = {};
    this.items.push(item);
    item['key'] = "login1_text_99437";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text_788446";
    item['en'] = "Your Next Appointment";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text2_541034";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen21_text_484484";
    item['en'] = "The dashboard this will show your upcomming jobs. Click the menu button to navigate to invitations, jobs or invoices. Click account to  manage your account ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen21_text2_492891";
    item['en'] = "Skip";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen21_text2_185821";
    item['en'] = "Next";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen22_textcopy_791632";
    item['en'] = "Next";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen22_text2_950061";
    item['en'] = "Skip";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen22_text_819916";
    item['en'] = "The dashboard this will show your upcomming jobs. Click the menu button to navigate to invitations, jobs or invoices. Click account to  manage your account ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen23_textcopy_549703";
    item['en'] = "Done";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen23_text2_971894";
    item['en'] = "Skip";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen23_text_151334";
    item['en'] = "The dashboard this will show your upcomming jobs. Click the menu button to navigate to invitations, jobs or invoices. Click account to  manage your account ";
    
    item = {};
    this.items.push(item);
    item['key'] = "login1_text_361346";
    item['en'] = "How it works";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem2_text_1018061";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem2_textcopy_1001686";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem2_textcopy2_306048";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem2_textcopy3_752708";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem2_textcopy4_844834";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem2_textcopy5_239353";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "scheduleditem2_button_151655";
    item['en'] = "View";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuitems_text_346821";
    item['en'] = "Jobs";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuitems_text_475992";
    item['en'] = "Jobs";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuitems_text_705473";
    item['en'] = "Jobs";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuitems_text2_698363";
    item['en'] = "Scheduled";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuitems_text2_678668";
    item['en'] = "Invoices";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_textblock_756323";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_textblock2_28331";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "jobdetails_textblock_817676";
    item['en'] = "By clicking accept you agree to the terms and conditions.";
    
    item = {};
    this.items.push(item);
    item['key'] = "login1_text_794051";
    item['en'] = "How it works";
    
    item = {};
    this.items.push(item);
    item['key'] = "login1_text_1026975";
    item['en'] = "How it works";
    
    item = {};
    this.items.push(item);
    item['key'] = "login1_textcopy_78504";
    item['en'] = "Sign Up";
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
