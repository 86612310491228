import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/ProfilePic_elImage_179424.jpg';

export default class ProfilePic extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_text = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserEmail'] : '');
    
    const style_elImage = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserPhoto'] : '') || img_elImage)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="ProfilePic">
        <div className="layoutFlow">
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.props.locStrings.profilepic_text_745254}</span>)}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_ProfilePic_elImage_179424">
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          </div>
        </div>
        
      </div>
    )
  }
  
}
