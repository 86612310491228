import React, { Component } from 'react';
import './App.css';
import btn_icon_64067 from './images/btn_icon_64067.png';
import btn_icon_985318 from './images/btn_icon_985318.png';
import btn_icon_986393 from './images/btn_icon_986393.png';

export default class MenuItems extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elText2 = (ev) => {
    // Go to screen 'scheduled'
    this.props.appActions.goToScreen('scheduled', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elText = (ev) => {
    // Go to screen 'Jobs'
    this.props.appActions.goToScreen('jobs', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elTextCopy = (ev) => {
    // Go to screen 'Invoices'
    this.props.appActions.goToScreen('invoices', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elIconButton = (ev) => {
    // Go to screen 'Jobs'
    this.props.appActions.goToScreen('jobs', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elIconButtonCopy = (ev) => {
    // Go to screen 'scheduled'
    this.props.appActions.goToScreen('scheduled', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elIconButtonCopy2 = (ev) => {
    // Go to screen 'Invoices'
    this.props.appActions.goToScreen('invoices', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_64067+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elIconButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_985318+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elIconButtonCopy2 = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_986393+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="MenuItems">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="baseFont elText2" style={style_elText2} onClick={this.onClick_elText2} >
            <div>{this.props.locStrings.menuitems_text2_698363}</div>
          </div>
          <div className="baseFont elText" style={style_elText} onClick={this.onClick_elText} >
            <div>{this.props.locStrings.menuitems_text_346821}</div>
          </div>
          <div className="baseFont elTextCopy" style={style_elTextCopy} onClick={this.onClick_elTextCopy} >
            <div>{this.props.locStrings.menuitems_text2_678668}</div>
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elIconButton">
            <button className="actionFont" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          </div>
          
          <div className="elIconButtonCopy">
            <button className="actionFont" style={style_elIconButtonCopy} onClick={this.onClick_elIconButtonCopy}  />
          </div>
          
          <div className="elIconButtonCopy2">
            <button className="actionFont" style={style_elIconButtonCopy2} onClick={this.onClick_elIconButtonCopy2}  />
          </div>
        </div>
        
      </div>
    )
  }
  
}
