import React, { Component } from 'react';
import './App.css';
import img_elDashboard from './images/Screen23_elDashboard_450866.jpg';
import img_elEllipse from './images/Screen21_elEllipse.png';
import img_elEllipseCopy from './images/Screen21_elEllipseCopy.png';
import img_elEllipseCopy2 from './images/Screen21_elEllipseCopy2.png';

export default class Screen21 extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elText2 = (ev) => {
    // Go to screen 'Login 1'
    this.props.appActions.goToScreen('login1', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elTextCopy = (ev) => {
    // Go to screen 'Screen 22'
    this.props.appActions.goToScreen('screen22', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elDashboard = {
      height: 'auto',
     };
    const style_elRectangle = {
      background: 'rgba(100, 100, 100, 1.000)',
     };
    const style_elText2 = {
      color: '#f3fff8',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elEllipse = {
      backgroundImage: 'url('+img_elEllipse+')',
      backgroundSize: '100% 100%',
     };
    const style_elEllipseCopy = {
      backgroundImage: 'url('+img_elEllipseCopy+')',
      backgroundSize: '100% 100%',
     };
    const style_elEllipseCopy2 = {
      backgroundImage: 'url('+img_elEllipseCopy2+')',
      backgroundSize: '100% 100%',
     };
    const style_elTextCopy = {
      color: '#f3fff8',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen Screen21" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.props.locStrings.screen21_text_484484}</div>
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <img className="elDashboard" style={style_elDashboard} src={img_elDashboard} alt=""  />
            <div className="elRectangle" style={style_elRectangle} />
            <div className="baseFont elText2" style={style_elText2} onClick={this.onClick_elText2} >
              <div>{this.props.locStrings.screen21_text2_492891}</div>
            </div>
            <div className="elEllipse" style={style_elEllipse} />
            <div className="elEllipseCopy" style={style_elEllipseCopy} />
            <div className="elEllipseCopy2" style={style_elEllipseCopy2} />
            <div className="baseFont elTextCopy" style={style_elTextCopy} onClick={this.onClick_elTextCopy} >
              <div>{this.props.locStrings.screen21_text2_185821}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
