import React, { Component } from 'react';
import './App.css';
import ProfilePic from './ProfilePic';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class AccountDetails extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elButton = (ev) => {
    // Go to screen 'Edit Account'
    this.props.appActions.goToScreen('editaccount', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_text = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserName'] : '');
    
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserEmail'] : '');
    
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AccountDetails appBg">
        <div className="layoutFlow">
          <div className="hasNestedComps elProfilePic">
            <div>
              <ProfilePic {...this.props} ref={(el)=> this._elProfilePic = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.props.locStrings.accountdetails_text_874480}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{value_textCopy !== undefined ? value_textCopy : (<span className="propValueMissing">{this.props.locStrings.accountdetails_text_240451}</span>)}</div>
            </div>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.props.locStrings.accountdetails_button_1036199}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
