import React, { Component } from 'react';
import './App.css';
import img_elPixel012 from './images/JobItem_elPixel012_141639.jpg';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class JobItem extends Component {

  // Properties used by this component:
  // date, time, service, document_key, jobId, payment, specificInstructions, streetAddress, city, postCode

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elButton = (ev) => {
    let newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_selectedJobId', newVal);
  
    // Go to screen 'Job Details'
    this.props.appActions.goToScreen('jobdetails', { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      backgroundColor: 'white',
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
     };
    const style_elPixel012 = {
      backgroundImage: 'url('+img_elPixel012+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy = this.props.date;
    
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_text2 = this.props.time;
    
    const style_elTextCopy2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy2 = this.props.service;
    
    const style_elTextCopy3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy3 = this.props.streetAddress;
    
    const style_elTextCopy4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy4 = this.props.city;
    
    const style_elTextCopy5 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy5 = this.props.postCode;
    
    const style_elTextCopy6 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy6 = this.props.payment;
    
    const style_elTextCopy7 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy7 = this.props.specificInstructions;
    
    const style_elTextCopy8 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy8 = this.props.JobId;
    
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="JobItem">
        <div className="background">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" style={style_elCard} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elPixel012">
            <div style={style_elPixel012} />
          </div>
          
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{value_textCopy !== undefined ? value_textCopy : (<span className="propValueMissing">{this.props.locStrings.jobitem_text2_750668}</span>)}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{value_text2 !== undefined ? value_text2 : (<span className="propValueMissing">{this.props.locStrings.jobitems_text2_667358}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="baseFont" style={style_elTextCopy2}>
              <div>{value_textCopy2 !== undefined ? value_textCopy2 : (<span className="propValueMissing">{this.props.locStrings.jobitem_textcopy_688762}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy3">
            <div className="baseFont" style={style_elTextCopy3}>
              <div>{value_textCopy3 !== undefined ? value_textCopy3 : (<span className="propValueMissing">{this.props.locStrings.jobitem_textcopy2_789885}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy4">
            <div className="baseFont" style={style_elTextCopy4}>
              <div>{value_textCopy4 !== undefined ? value_textCopy4 : (<span className="propValueMissing">{this.props.locStrings.jobitem_textcopy3_369397}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy5">
            <div className="baseFont" style={style_elTextCopy5}>
              <div>{value_textCopy5 !== undefined ? value_textCopy5 : (<span className="propValueMissing">{this.props.locStrings.jobitem_textcopy4_212587}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy6">
            <div className="baseFont" style={style_elTextCopy6}>
              <div>{value_textCopy6 !== undefined ? value_textCopy6 : (<span className="propValueMissing">{this.props.locStrings.jobitem_textcopy4_928574}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy7">
            <div className="baseFont" style={style_elTextCopy7}>
              <div>{value_textCopy7 !== undefined ? value_textCopy7 : (<span className="propValueMissing">{this.props.locStrings.jobitem_textcopy6_199691}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy8">
            <div className="baseFont" style={style_elTextCopy8}>
              <div>{value_textCopy8 !== undefined ? value_textCopy8 : (<span className="propValueMissing">{this.props.locStrings.jobitem_textcopy7_969194}</span>)}</div>
            </div>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.props.locStrings.jobitem_button_680217}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
