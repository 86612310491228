import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_firebaseInvoice extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['zohoId'] = "96766000000034002";
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "17-19 Torrington ParkInventory & Check In";
    item['time'] = "10 AM";
    item['document_key'] = "3JitsTzOjU5X5Ni4SAVl";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['date'] = "Wednesday, October 23, 2019";
    item['zohoBalance'] = "79.9";
    item['streetAddress'] = "17-19 Torrington Park";
    item['zohoUrl'] = "https://zohosecurepay.eu/invoice/beprofessional/secure?CInvoiceID=2-9dbfec6007c67d9f72c90501d0715b0d5b3575ccf8fb72199b2703d62010164978e80664afad51a1dbc7a70bef05db0396a12b760f841f84da35790b71c5aaae11e80a707f5b3196 ";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['zohoId'] = "96766000000034002";
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "17-19 Torrington ParkInventory & Check In";
    item['time'] = "10 AM";
    item['document_key'] = "A814cNSkPrhlf36Kujws";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['date'] = "Wednesday, October 23, 2019";
    item['zohoBalance'] = "79.9";
    item['streetAddress'] = "17-19 Torrington Park";
    item['zohoUrl'] = "https://zohosecurepay.eu/invoice/beprofessional/secure?CInvoiceID=2-9dbfec6007c67d9f72c90501d0715b0d5b3575ccf8fb72199b2703d62010164978e80664afad51a1dbc7a70bef05db0396a12b760f841f84da35790b71c5aaae11e80a707f5b3196 ";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['zohoId'] = "96766000000034002";
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "17-19 Torrington ParkInventory & Check In";
    item['time'] = "10 AM";
    item['document_key'] = "BYPZKFaV0D1XcPPX1jLn";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['date'] = "Wednesday, October 23, 2019";
    item['zohoBalance'] = "79.9";
    item['streetAddress'] = "17-19 Torrington Park";
    item['zohoUrl'] = "https://zohosecurepay.eu/invoice/beprofessional/secure?CInvoiceID=2-9dbfec6007c67d9f72c90501d0715b0d5b3575ccf8fb72199b2703d62010164978e80664afad51a1dbc7a70bef05db0396a12b760f841f84da35790b71c5aaae11e80a707f5b3196 ";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['zohoId'] = "96766000000034002";
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "17-19 Torrington ParkInventory & Check In";
    item['time'] = "10 AM";
    item['document_key'] = "v1jZuWIKQScNyWNKnC9Z";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['date'] = "Wednesday, October 23, 2019";
    item['zohoBalance'] = "79.9";
    item['streetAddress'] = "17-19 Torrington Park";
    item['zohoUrl'] = "https://zohosecurepay.eu/invoice/beprofessional/secure?CInvoiceID=2-9dbfec6007c67d9f72c90501d0715b0d5b3575ccf8fb72199b2703d62010164978e80664afad51a1dbc7a70bef05db0396a12b760f841f84da35790b71c5aaae11e80a707f5b3196 ";
    item.key = key++;
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      addItemPromise = collection.doc(item.document_key).set(item);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          item.document_key = docRef.id;
          item.document_ref = docRef;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    //const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = item.document_ref;
    
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    //const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = item.document_ref;
    
    docRef.update((({ key, document_ref, document_key, ...item }) => (item))( item ))
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
