import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_book extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10";
    item['payment'] = "100";
    item['document_key'] = "1bqPGy1bxpR7rLCj4QyP";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['streetAddress'] = "17-19 Torrington Park";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "TW134GQ";
    item['specificInstructions'] = "Aleksandra - +447402620020\nIncase you couldn't reach, Please call me directly.";
    item['job_document_key'] = "ZXTDLKkcBrK8hgMbL4Y0";
    item['streetAddress'] = "Highfield Road";
    item['time'] = "05 PM";
    item['invoiced'] = "0";
    item['date'] = "Sunday, December 1, 2019";
    item['city'] = "Feltham";
    item['service'] = "Inventory Report";
    item['document_key'] = "1nws2XFTfsxE2VDpNV9S";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['zoho_id'] = "";
    item['payment'] = "£ 100";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "22XIPdcEAp8D4vRjuEk1";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "se25 6xb";
    item['city'] = "croydon";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 80";
    item['document_key'] = "462YZUdZGepccaziEWeQ";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Thursday, November 7, 2019";
    item['specificInstructions'] = "1412 is the code for the key safe";
    item['streetAddress'] = "17-19 Torrington Park";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "4FfNA7SkCEwlussel9OQ";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "4uiyun2w2rTQt31wTpz1";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "9lL5sqB0VZrH0GmAsMnF";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "CVDOvU2BpZhnlqIiYuRj";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "FPCPQd8GBjUYFCASIfj8";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "BJEFRfLmJVmjCyWIT28M";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "Guz4EelpdUlqj1LG7WIh";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "H2BP04l4CoBDiFrZ4Ihr";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['streetAddress'] = "17-19 Torrington Park";
    item['job_document_key'] = "2YryvnWSotY2ro5l6HRu";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['document_key'] = "J3CBgMtpMG1ZCMVHeOgl";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['zoho_id'] = "96766000000034002";
    item['zoho_url'] = "https://zohosecurepay.eu/invoice/beprofessional/secure?CInvoiceID=2-9dbfec6007c67d9f72c90501d0715b0d5b3575ccf8fb72199b2703d62010164978e80664afad51a1dbc7a70bef05db0396a12b760f841f84da35790b71c5aaae11e80a707f5b3196 ";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "se25 6xb";
    item['city'] = "croydon";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 80";
    item['document_key'] = "JmhNnzGHzIAAGKNezwVU";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Thursday, November 7, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['streetAddress'] = "17-19 Torrington Park";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "TojqTeCO6t3hyvH7WaLS";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['streetAddress'] = "17-19 Torrington Park";
    item['job_document_key'] = "t2x13Nb3iFNuTruED8Zd";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "WaCuzKCY5Q7BdelfdegX";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "XmlfzuwAhpAUg7fV7BNU";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['streetAddress'] = "17-19 Torrington Park";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "cGhEY04nvGw4fYI9aAb1";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "jzyHeYUFBbD7M5S16UZa";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['streetAddress'] = "17-19 Torrington Park";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "oKqz1AnKomsnUeKGkC9S";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "oxwGvMTJI7CXDopOnl2c";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['streetAddress'] = "17-19 Torrington Park";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "qssiwrrAyYV4B6i671YA";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['streetAddress'] = "17-19 Torrington Park";
    item['time'] = "10 AM";
    item['date'] = "Wednesday, October 23, 2019";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['document_key'] = "tGybpTc0Zn0G9zcNOcYN";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['zoho_id'] = "";
    item['payment'] = "£ 100";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "trXqHIyKgoB5CC7CEu3X";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "2YryvnWSotY2ro5l6HRu";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "uYR211YNuAka8Pj4w0bl";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "vj7JWSwZP0nSdezeIfFD";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['zoho_id'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "v7IeJUdCgRBkbRHbe1En";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "se25 6xb";
    item['specificInstructions'] = "1412 is the code for the key safe";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['job_document_key'] = "ZZFa1I7BEPw4WhfMxj8P";
    item['streetAddress'] = "whitehorse lane";
    item['time'] = "10 AM";
    item['date'] = "Thursday, November 7, 2019";
    item['city'] = "croydon";
    item['service'] = "Inventory & Check In";
    item['document_key'] = "x9uFjzbJClPLSPiV0XgU";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['payment'] = "£ 80";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['postCode'] = "N12 9TB";
    item['city'] = "London";
    item['service'] = "Inventory & Check In";
    item['time'] = "10 AM";
    item['payment'] = "£ 100";
    item['document_key'] = "yavzuo77yIgMk2zCvqb2";
    item['clerkEmail'] = "admin@pixelinventory.co.uk";
    item['customerEmail'] = "amirdehnad@gmail.com";
    item['date'] = "Wednesday, October 23, 2019";
    item['specificInstructions'] = "Please follow up with Doug from Foxtons on 07875801485";
    item['streetAddress'] = "17-19 Torrington Park";
    item.key = key++;
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      addItemPromise = collection.doc(item.document_key).set(item);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          item.document_key = docRef.id;
          item.document_ref = docRef;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    console.log("delete from firebase: ", item);
  
    //const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = item.document_ref;
    
    docRef.delete()
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    //const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = item.document_ref;
    
    docRef.update((({ key, document_ref, document_key, ...item }) => (item))( item ))
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
