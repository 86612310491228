import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class BookJob extends Component {

  // Properties used by this component:
  // date, time, service, city, postCode, payment, specificInstructions, streetAddress

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elButton = (ev) => {
    this.sendData_button_to_book();
  
    // Go to screen 'Dashboard'
    this.props.appActions.goToScreen('dashboard', { transitionId: 'fadeIn' });
  
  }
  
  
  sendData_button_to_book = () => {
    const dataSheet = this.props.appActions.getDataSheet('book');
  
    let row = this.props.dataSheetRow || {
      "date": this.props.date,
      "time": this.props.time,
      "service": this.props.service,
      "city": this.props.city,
      "postCode": this.props.postCode,
      "payment": this.props.payment,
      "specificInstructions": this.props.specificInstructions,
      "streetAddress": this.props.streetAddress,
    };
    row = { ...row, 
      clerkEmail: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUserEmail'] : ''),
      job_document_key: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selectedJobId'] : ''),
    };
    if (this.props.dataSheetId === dataSheet.id) {
      this.props.appActions.updateInDataSheet('book', row);
    } else {
      this.props.appActions.addToDataSheet('book', row);
    }
  }
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_text = this.props.date;
    
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy = this.props.time;
    
    const style_elTextCopy2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy2 = this.props.service;
    
    const style_elTextCopy8 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy8 = this.props.streetAddress;
    
    const style_elTextCopy3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy3 = this.props.city;
    
    const style_elTextCopy4 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy4 = this.props.postCode;
    
    const style_elTextCopy5 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy5 = this.props.payment;
    
    const style_elTextCopy6 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const value_textCopy6 = this.props.specificInstructions;
    
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="BookJob">
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.props.locStrings.component1_text_403854}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{value_textCopy !== undefined ? value_textCopy : (<span className="propValueMissing">{this.props.locStrings.bookjob_text_754373}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="baseFont" style={style_elTextCopy2}>
              <div>{value_textCopy2 !== undefined ? value_textCopy2 : (<span className="propValueMissing">{this.props.locStrings.bookjob_text_587116}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy8">
            <div className="baseFont" style={style_elTextCopy8}>
              <div>{value_textCopy8 !== undefined ? value_textCopy8 : (<span className="propValueMissing">{this.props.locStrings.bookjob_textcopy3_163090}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy3">
            <div className="baseFont" style={style_elTextCopy3}>
              <div>{value_textCopy3 !== undefined ? value_textCopy3 : (<span className="propValueMissing">{this.props.locStrings.bookjob_text_317930}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy4">
            <div className="baseFont" style={style_elTextCopy4}>
              <div>{value_textCopy4 !== undefined ? value_textCopy4 : (<span className="propValueMissing">{this.props.locStrings.bookjob_text_915320}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy5">
            <div className="baseFont" style={style_elTextCopy5}>
              <div>{value_textCopy5 !== undefined ? value_textCopy5 : (<span className="propValueMissing">{this.props.locStrings.bookjob_text_789122}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy6">
            <div className="baseFont" style={style_elTextCopy6}>
              <div>{value_textCopy6 !== undefined ? value_textCopy6 : (<span className="propValueMissing">{this.props.locStrings.bookjob_text_398220}</span>)}</div>
            </div>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.props.locStrings.component1_button_717367}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
